import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FormControl, NativeSelect, makeStyles, Button } from '@material-ui/core';
import MicIcon from '../assets/images/svg/mic-icon.svg';
import MutedMicIcon from '../assets/images/svg/muted-mic-icon.svg';
import SoundIcon from '../assets/images/svg/sound-icon.svg';

import SoundMuted from '../assets/images/svg/sound-muted.svg';
import SoundOff from '../assets/images/svg/sound-off.svg';
import SoundOn from '../assets/images/svg/sound-on.svg';

const useStyles = makeStyles(theme => ({
  deviceSelect: {
    margin: 'auto 80px',
  },

  selectLabel: {
    fontWeight: 'bold',
  },

  select: {
    fontWeight: 'normal',
    marginRight: 32,
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center'
  },
  deviceIcon: {
    display: 'flex',
    marginRight: 10,
  },
  speakerTextButton: {
    color: '#858585',
    textTransform: 'initial'
  }
}));

const MediaDevices = ({ disabled, muted, onInputDeviceChange, onOutputDeviceChange }) => {
  const classes = useStyles();
  const [devices, setDevices] = useState({ input: [], output: [] });
  const [inputDeviceId, setInputDeviceId] = useState('');
  const [outputDeviceId, setOutputDeviceId] = useState('');

  const updateInputDeviceId = useCallback(v => {
    setInputDeviceId(v.target.value);
    onInputDeviceChange(v.target.value);
  }, []);
  const updateOutputDeviceId = useCallback(v => {
    setOutputDeviceId(v.target.value);
    onOutputDeviceChange(v.target.value);
  }, []);

  const updateDeviceList = () => {
    const mediaDevices = { input: [], output: [] };

    Promise.all([navigator.mediaDevices.getUserMedia({ audio: true }), navigator.mediaDevices.enumerateDevices()])
      .then(([, resDevices]) => {
        resDevices.forEach(device => {
          switch (device.kind) {
            case 'audioinput':
              mediaDevices.input.push({
                ...device.toJSON(),
                text: device.label || `Microphone ${mediaDevices.input.length + 1}`,
              });
              break;
            case 'audiooutput':
              mediaDevices.output.push({
                ...device.toJSON(),
                text: device.label || `Speaker ${mediaDevices.output.length + 1}`,
              });
              break;
            default:
              break;
          }
        });

        setDevices(mediaDevices);
      })
      .catch(() => {
        setDevices({
          input: [{ deviceId: '', text: 'Microphone is blocked' }],
          output: [{ deviceId: '', text: 'Speaker is blocked' }],
        });
      });
  };

  const deviceListListener = useCallback(() => {
    updateDeviceList();
  }, []);

  useEffect(() => {
    updateDeviceList();

    navigator.mediaDevices.addEventListener('devicechange', deviceListListener);

    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', deviceListListener);
    };
  }, []);

  const handleTextSpeaker = useCallback((e) => {
    e.preventDefault();
    // TODO run testing for the selected speaker
  }, []);

  return (
    <>
      <FormControl className={classes.deviceSelect}>
        <span className={classes.selectLabel}>Microphone</span>
        <div className={classes.flexCenter}>
          <NativeSelect
            value={inputDeviceId}
            onChange={updateInputDeviceId}
            className={classes.select}
            disabled={disabled}
          >
            {devices.input.map(device => (
              <option key={device.deviceId} value={device.deviceId}>
                {device.text}
              </option>
            ))}
          </NativeSelect>
          <div className={classnames(classes.microphone, classes.flexCenter)}>
            <span className={classes.deviceIcon}>
              {muted ? <MutedMicIcon /> : <MicIcon />}
            </span>
            {muted ? <SoundMuted /> : <SoundOff />}
          </div>
        </div>
      </FormControl>

      <FormControl className={classes.deviceSelect}>
        <span className={classes.selectLabel}>Speakers</span>
        <div className={classes.flexCenter}>
          <NativeSelect
            value={outputDeviceId}
            onChange={updateOutputDeviceId}
            className={classes.select}
            disabled={disabled}
          >
            {devices.output.map(device => (
              <option key={device.deviceId} value={device.deviceId}>
                {device.text}
              </option>
            ))}
          </NativeSelect>
          <Button size="small" className={classes.speakerTextButton} startIcon={<SoundIcon />} onClick={handleTextSpeaker}>Test</Button>
        </div>
      </FormControl>
    </>
  );
};

MediaDevices.propTypes = {
  disabled: PropTypes.bool,
  muted: PropTypes.bool,
  onInputDeviceChange: PropTypes.func,
  onOutputDeviceChange: PropTypes.func,
};

MediaDevices.defaultProps = {
  disabled: false,
  muted: false,
  onInputDeviceChange: () => null,
  onOutputDeviceChange: () => null,
};

export default MediaDevices;
