import React, { useCallback, useRef, useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import Tooltip from '@material-ui/core/Tooltip';

import ChannelImage from '../../assets/images/channel.png';
import SEO from '../../components/seo';
import Card from '../../components/Card';
import MediaDevices from '../../components/MediaDevices';
import Spinner from '../../components/Spinner';
import Button from '../../components/Button';
import NavMenu from '../../components/NavMenu';
import NoRoomImage from '../../assets/images/no-room.png';
import MicImage from '../../assets/images/svg/mic.svg';

import agoraService from '../../services/agoraService';
import audioService from '../../services/audioService';
import authService from '../../services/authService';
import dataService from '../../services/dataService';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    textAlign: 'center',
    maxWidth: '350px',
  },

  roomCard: {
    width: '100%',
    textAlign: 'initial',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    borderRadius: 8,

    '& h2': {
      margin: 0,
      fontSize: 21,
    },

    '& ul': {
      listStyle: 'none',
      padding: 0,
      margin: 0,
      display: 'flex',
    },

    '& li + li': {
      marginLeft: 5,
    },

    '& a': {
      color: '#0B7CE5',
      textDecoration: 'none',
    },
  },

  liveBadge: {
    padding: '4px 8px',
    fontSize: 11,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: ' #FF2D2D',
    background: 'rgba(255, 45, 45, 0.1)',
    borderRadius: 8,

    '& + $channelBadge': {
      marginLeft: 10,
    },
  },

  channelBadge: {
    display: 'inline-flex',
    margin: '0 4px',
    padding: '4px 8px',
    fontSize: 11,
    fontWeight: 'bold',
    color: ' #0B7CE5',
    background: 'rgba(11, 124, 229, 0.1)',
    borderRadius: 8,
  },

  channel: {
    padding: '0 0 0 18px',
    fontSize: 11,
    fontWeight: 'bold',
    color: ' #0B7CE5',
    background: `url(${ChannelImage}) no-repeat left center`,
    backgroundSize: '14px',
  },

  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '0 0 140px',
    width: '100%',
    background: '#f5f5f5',
  },

  defaultView: {
    '& img': {
      maxWidth: 270,
    },

    '& > p': {
      marginTop: 40,
      marginBottom: 32,
    },
  },

  roomView: {
    '& $roomCard > figure': {
      margin: '20px 0',
    },

    '& $roomCard img': {
      display: 'block',
      width: 40,
      height: 40,
      borderRadius: '50%',
    },

    '& $roomCard > p': {
      margin: '10px 0 0',
      color: '#858585',
    },

    '& > p': {
      marginTop: 40,
    },

    '& > button': {
      marginTop: 15,
    },
  },

  liveRoomView: {
    '& h2': {
      marginTop: 10,
    },

    '& $roomCard': {
      marginBottom: 20,
    },

    '& $roomCard figure': {
      textAlign: 'center',
    },

    '& $roomCard figure p': {
      fontSize: 17,
      fontWeight: 'bold',
      margin: 0,
    },

    '& $roomCard figure img': {
      margin: '0 auto 8px',
      width: 150,
      height: 150,
      objectFit: 'cover',
      borderRadius: '50%',
      border: '5px solid #51E9B8',
      padding: 7,
    },
  },

  muteButton: {
    display: 'block',
    width: 56,
    height: 56,
    padding: 13,
    margin: '30px auto 15px',
    outline: 'none',
    cursor: 'pointer',
    border: 'none',
    background: '#fff',
    borderRadius: '50%',
    boxShadow: '0px 4px 12px rgb(0 0 0 / 10%)',

    '&.muted': {
      position: 'relative',
      background: '#f44336',

      '&:before': {
        content: '""',
        display: 'block',
        width: 5,
        height: 40,
        position: 'absolute',
        top: '50%',
        left: '50%',
        border: '1px solid #f44336',
        background: '#fff',
        borderRadius: 99,
        transform: 'translate(-50%, -50%) rotate(45deg)',
      },

      '&:after': {
        content: '"Muted"',
        position: 'absolute',
        top: '100%',
        left: '50%',
        fontSize: 11,
        color: '#f44336',
        transform: 'translate(-50%, 5px)',
      },

      '& > svg path': {
        fill: '#fff',
      },
    },
  },

  navMenu: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 15,

    '& img': {
      display: 'block',
      width: 32,
      height: 32,
      borderRadius: '50%',
    },

    '& p': {
      marginLeft: 10,
      fontWeight: 'bold',
    },

    '& button': {
      marginLeft: 20,
    },
  },
  description: {
    color: '#858585',
    margin: '24px 0',
  },
  refreshButton: {
    width: 56,
    height: 56,
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    color: '#1F1F1F',
  },
}));

const useStylesTooltip = makeStyles(() => ({
  arrow: {
    color: '#1F1F1F',
  },
  tooltip: {
    backgroundColor: '#1F1F1F',
    padding: 16,
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    fontSize: 15,
    fontWeight: 'normal',
  },
}));

const Room = () => {
  const classes = useStyles();
  const tooltipClasses = useStylesTooltip();
  const [userData, setUserData] = useState(null);
  const [liveRoom, setLiveRoom] = useState(null);
  const [inputDevice, setInputDevice] = useState(null);
  const [outputDevice, setOutputDevice] = useState(null);
  const [muted, setMuted] = useState(false);

  /* Helper functions */
  const onInputDeviceChange = useCallback(deviceId => {
    setInputDevice(deviceId);
    agoraService.setInputDevice(deviceId);
  }, []);

  const onOutputDeviceChange = useCallback(deviceId => {
    setOutputDevice(deviceId);
    agoraService.setOutputDevice(deviceId);
  }, []);

  const updateAudioAccess = useCallback(
    (endpoint, body = {}) => {
      if (!userData?.id) return;

      audioService
        .updateAudioAccess(endpoint, { userId: userData.id, ...body })
        .then(() => {})
        .catch(err => console.log(err));
    },
    [userData?.id],
  );

  const onMuteButtonClick = useCallback(() => {
    setMuted(wasMuted => {
      const isMuted = !wasMuted;

      updateAudioAccess('updateMic', { micStatus: !isMuted });

      if (isMuted) {
        agoraService.mute();
      } else {
        agoraService.unmute();
      }

      return isMuted;
    });
  }, [userData?.id]);

  const endWebClient = useCallback(() => {
    updateAudioAccess('releaseSpeaking');

    if (agoraService.connected) {
      agoraService.disconnect();
      setLiveRoom(null);
    }
  }, [userData?.id]);

  const signout = useCallback(() => {
    endWebClient();
    authService.logout();
    navigate('/app/login');
  }, [userData?.id]);

  /* Fetch rooms */
  const fetchRooms = async () => {
    setUserData(await dataService.getUserData());
  };

  useEffect(() => {
    fetchRooms();

    return () => {
      updateAudioAccess('releaseSpeaking');
    };
  }, [userData?.id]);

  /* Agora */
  useEffect(() => {
    if (liveRoom) {
      agoraService.connect({
        channel: liveRoom.id,
        uid: userData.id,
        inputDeviceId: inputDevice,
        outputDeviceId: outputDevice,
      });
    }

    return () => {
      if (agoraService.connected) {
        agoraService.disconnect();
      }
    };
  }, [liveRoom?.id]);

  const onClickSpeakListen = useCallback(
    roomValue => {
      setLiveRoom(roomValue);
      updateAudioAccess('acquireSpeaking');
      updateAudioAccess('updateMic', { micStatus: true });
    },
    [userData?.id],
  );

  /* Render functions */
  const renderDefault = () => (
    <div className={classes.defaultView}>
      <h1>Welcome, {userData.firstName}!</h1>
      <img src={NoRoomImage} />
      <p>
        In order to use your external audio, you must first create a room or become a speaker in a room in the mobile
        app.
        <br />
        (refresh this page if you don't see your room)
      </p>
      <IconButton className={classes.refreshButton} aria-label="refresh" onClick={() => window.location.reload()}>
        <RefreshIcon />
      </IconButton>
    </div>
  );

  const renderUserRoom = () => {
    console.log({ userData });
    const roomMembers = [...(userData.room.feedSpeakers || []), ...(userData.room.feedListeners || [])].map(
      listener => `${listener.firstName} ${listener.lastName}`,
    );

    let listenersString = '';

    if (roomMembers.length === 1) {
      listenersString = `${roomMembers[0]} is here`;
    } else if (roomMembers.length === 2) {
      listenersString = `${roomMembers[0]} and ${roomMembers[1]} are here`;
    } else if (roomMembers.length === 3) {
      listenersString = `${roomMembers[0]}, ${roomMembers[1]} and ${roomMembers[2]} are here`;
    } else if (roomMembers.length > 3) {
      listenersString = `${roomMembers[0]}, ${roomMembers[1]} and ${roomMembers.length - 2} others are here`;
    }

    const tags = [];

    return (
      <div className={classes.roomView}>
        <h1>It looks like you're hosting a room!</h1>

        <Card className={classes.roomCard}>
          <header>
            <span className={classes.liveBadge}>Live</span>

            <span className={classes.channelBadge}>
              <span className={classes.channel}>{userData.room.orbitTitle}</span>
            </span>
          </header>

          <figure>
            <img src={userData.photoUrl} />
          </figure>

          <h2>{userData.room.title}</h2>

          <p>
            {listenersString}

            {tags.length > 0 && (
              <ul>
                {tags.map(tag => (
                  <li key={tag.title}>
                    <a href={tag.link}>{tag.title}</a>
                  </li>
                ))}
              </ul>
            )}
          </p>
        </Card>

        <p>
          Note: This desktop app only controls your audio.{' '}
          <b>Be sure to have your mobile app ready for all other functions (discussions, speaker requests, etc.)</b>
        </p>

        <Button onClick={() => onClickSpeakListen(userData.room)}>Speak and listen here</Button>
      </div>
    );
  };

  const renderLiveRoom = () => {
    return (
      <div className={classes.liveRoomView}>
        <Card className={classes.roomCard}>
          <header>
            <span className={classes.liveBadge}>Live</span>

            <span className={classes.channelBadge}>Group chat</span>
          </header>

          <h2>{liveRoom.title}</h2>

          <p className={classes.channel}>{liveRoom.orbitTitle}</p>

          <figure>
            <img src={userData.photoUrl} />
            <p>You</p>
          </figure>

          <button className={classnames(classes.muteButton, { muted })} onClick={onMuteButtonClick}>
            <MicImage />
          </button>
        </Card>

        <p className={classes.description}>
          Note: This desktop app only controls your audio.{' '}
          <b>Be sure to have your mobile app ready for all other functions (discussions, speaker requests, etc.)</b>
        </p>

        <Tooltip
          classes={tooltipClasses}
          title="Pressing this will close your audio connection on the web and move back to your phone. This will not close your room."
          arrow
          placement="top"
        >
          <Button className={classes.endAudioButton} secondary onClick={endWebClient}>
            End audio on web client
          </Button>
        </Tooltip>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <SEO title="Locker Room" />

      <NavMenu>
        <div className={classes.navMenu}>
          {userData && (
            <>
              <img src={userData.photoUrl} />
              <p>{userData.firstName}</p>
            </>
          )}
          <Button secondary small round onClick={signout}>
            Sign Out
          </Button>
        </div>
      </NavMenu>

      <div className={classes.content}>
        {userData ? userData?.room ? liveRoom ? renderLiveRoom() : renderUserRoom() : renderDefault() : <Spinner />}
      </div>

      <footer className={classes.footer}>
        <MediaDevices
          muted={muted}
          disabled={!Boolean(userData)}
          onInputDeviceChange={onInputDeviceChange}
          onOutputDeviceChange={onOutputDeviceChange}
        />
      </footer>
    </div>
  );
};

export default Room;
