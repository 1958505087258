import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    display: 'block',
    width: 20,
    height: 20,

    '&:before, &:after': {
      content: '""',
      display: 'block',
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      border: '2px solid',
      borderColor: 'red',
      opacity: 0.2,
      borderRadius: '50%',
    },

    '&:after': {
      opacity: 1,
      borderTopColor: '#fe5500',
      borderRightColor: 'transparent',
      borderLeftColor: 'transparent',
      borderBottomColor: 'transparent',
      animation: '1000ms $spin infinite both cubic-bezier(0.73, 0.06, 0.26, 0.94)',
    }
  },

  '@keyframes spin': {
    to: { transform: 'rotate(360deg)', }
  },
}));

const Spinner = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root} />
  );
};

export default Spinner;
