import React from 'react';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 20,
    background: '#fff',
    borderRadius: 5,
    boxShadow: '0 10px 10px rgba(0, 0, 0, 0.2)',
  },
}));

const Card = ({ className, children }) => {
  const classes = useStyles();

  return (
    <div className={classnames(className, classes.root)}>
      {children}
    </div>
  );
};

export default Card;
